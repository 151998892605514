<template>
  <v-app>
    <div />
  </v-app>
</template>

<script>
  import firebase from 'firebase/app'
  import 'firebase/messaging'
  import axios from 'axios'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')

  export default {
    name: 'Logout',
    created () {
      const messaging = firebase.messaging()
      messaging.getToken().then(token => {
        console.log(token)
        axios
          .delete(`/v1/user/platform/${token}`)
          .then(response => {
            this.$store
              .dispatch('destroyToken')
              .then(response => {
                messaging.deleteToken().then(() => {
                  console.log('berhasil')
                })

                this.$router.push({ name: 'Login' })
              })
              .catch(e => {
                this.$router.push({ name: 'Login' })
              })
          })
      })
    },
  }
</script>

<style></style>
